import logo from './logo.svg';
import './App.css';

import derp0 from './imgs/derp0.jpg'
import derp1 from './imgs/derp1.jpg'
import derp2 from './imgs/derp2.jpg'
import derp3 from './imgs/derp3.jpg'

import berp0 from './imgs/berp0.jpg'
import berp1 from './imgs/berp1.jpg'
import berp2 from './imgs/berp2.jpg'

import derpmap from './imgs/derpmap.jpg'
import derptoken from './imgs/derptoken.jpg'

import snackburger from './imgs/snackBurger.jpg'
import snackMeal from './imgs/snackMeal.jpg'

import snackCola from './imgs/snackCola.jpg'
import snackChips from './imgs/snackChips.jpg'

import team from './imgs/rats.jpg'

import {
  Link,
  Outlet
} from "react-router-dom";

const Nav = ()=> (
<div className="App__Nav">
    <h1>Derpe Docs</h1>
    <Link to="section1" className="c1">Derpe Dewdz</Link>
    <Link to="section2" className="c2">Derps</Link>
    <Link to="section3" className="c3">$DERP</Link>
    <Link to="section4" className="c4">Snackz</Link>
    <Link to="section5" className="c4">Berps</Link>
    <Link to="section6" className="c4">Derpmap</Link>
</div>
  )

export function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Nav />

      </header>
      <div className="App-body">
        <Outlet />
      </div>
    </div>
  );
}

export function Lander() {
  return(
    <div className="lander">
      
      <p></p>
    </div>
    )
}

export function Section1() {
  return(
    <div className="section">
      <h1>Derpe Dewdz</h1>
      <p>Derpe Dewdz are a collection of 100 handcrafted NFTs. They were auctioned one-by-one on Paintswap on the Fantom network.</p>
      <p>You can check them out on Paintswap <a href="https://paintswap.finance/marketplace/fantom/collections/derpe-dewdz" target="_blank">here</a>.</p>

    </div>
    )
}

export function Section2() {
  const supply = 2000
  const price = 100
  const free = 100

  const treasury = (supply - free) * price

  return(
    <div className="section">
      <h1>The Derps</h1>
      <div className="imgs"><img src={derp3} /></div>
      <p>The Derps are a generative NFT collection on Sonic Mainnet.</p>
      <p>Total Supply: {supply}</p>
      <p>Mint Price: {price}</p>
      <p>Free Mints: {free} Derps are reserved for each Derpe Dewd on Fantom. They will be minted to the treasury and the ID# of your Derpe Dewd will match the ID# of your Derp</p>
      <p>Mint Date: TBA</p>
      <p>Mint Mechanics: The mint will remain open for 72 hours after which it will be manually closed. If the community believes the mint window should be extended it will be taken into consideration.</p>
      <p>30% of the $DERP token supply will be claimable by Derp NFT holders through various mining games.</p>
      <div className="imgs"><img src={derp0} /> <img src={derp1} /><img src={derp2} /></div>
      <h2>The Derps Treasury</h2>
      <p>Funds from the mint will be divided into five sub-treasuries.</p>
      <ul>
        <li>Collection Market Making // 10% // {0.1*treasury} $S</li>
        <li>$DERP Market Making // 20% // {0.2*treasury} $S</li>
        <li>Locks and Bribes // 10% // {0.1*treasury} $S</li>
        <li>General Treasury // 40% // {0.4*treasury} $S</li>
        <li>Team // 20% // {0.2*treasury} $S</li>
      </ul>
      <h3>Collection Market Making</h3>
      <p>10% of the mint funds, {0.1*treasury} $S, will be used to perform Market Making for the Derps collection.</p>
      <h4>Collection Offers</h4>
      <p>After the collection is minted out, this fund will be used to set Collection Offers starting at 80% of the mint price, 80 $S. This will provide liquidity to users looking to paperhand their Derps. </p>
      <p>After launch, this fund will place 112 Collection Offers at 80 $S. This means their is enough to buyback all free mints and 12 other Derps will paperhanded owners.</p>
      <h4>Selling Purchased Derps</h4>
      <p>Derps purchased through Collection Offers will be listed at a MINIMUM 10% markup from the mint price, 110 $S. If the floor dips below the mint price it will because you paperhanded, not us.</p>
      <h4>Details and Situations</h4>
      <p>All $S in this fund will be entered into Collection Offers shortly after launch. New $S inflow due to sales will be re-entered within 2 weeks of receipt. </p>
      <p>If the fund runs out of $S the NFTs will remain listed at 10% markup. Collection Offers will cease until demand naturally returns.</p>
      <p>The fund will continue trading at 80% discount/10% markup until it has accumulated enough $S to support 50% of the markets liquidity. At that point, it is possible collection offers and listing prices will be INCREASED. Both will be increased simultaneously.</p>
      <h3>$DERP Market Making</h3>
      <p>20% of the mint, {0.2*treasury} $S, will be used to perform Market Making for the $DERP ERC20 token. All of the $S will be used to seed the initial liquidity of the token.</p>
      <h4>Initial Liquidity</h4>
      <p>30% of the $DERP supply will be paired with {0.2*treasury} $S on Shadow Dex. This fund will own the liquidity.</p>
      <h4>Liquidity Management</h4>
      <p>Liquidity will not be burned. We want to be able to adapt to an evolving ecosystem. If liquidity is moved from one dex to another, even partially, it will be transparent. Any farming opportunities undertaken by this fund will be partially compounded, with the remainder of the rewards allocated to the team.</p>
      <h3>Locks and Bribes</h3>
      <p>10% of the mint, {0.1*treasury} $S, will be used to purchase locks and bribe voters for $DERP liquidity.</p>
      <p>We will likely use this initial amount to first purchase and lock SHADOW, and later for bribes.</p>
      <h3>General Treasury</h3>
      <p>40% of the mint, {0.4*treasury} $S, will be allocated to the General Treasury.</p>
      <p>This fund can be used for anything beneficial to the Derpeverse. Including the replenishment of other portions of the treasury.</p>
      <h3>Team</h3>
      <p>20% of the mint, {0.2*treasury} $S, will be allocated to the Team.</p>
    </div>
    )
}

export function Section3() {
  return(
    <div className="section">
      <h1>The $DERP Token</h1>
      <p>The $DERP token is a fixed supply ERC20 token. It is claimable daily by Derp holders. It is just for fun.</p>
      <p>Total Supply // 420,696,696</p>
      <ul>
        <li>Initial Liquidity // 30%</li>
        <li>$DERP emissions // 30% </li>
        <li>General Treasury // 20% </li>
        <li>Bribes // 10% </li>
        <li>Team Locks // 10% </li>
      </ul>

      <h2>Initial Liquidity</h2>
      <p>This $DERP is sent to the Market Making fund and added to liquidity on Shadow.</p>
      <h2>$DERP emissions</h2>
      <p>Every Derp NFT can claim ??? $DERP per day. This amount does NOT accumulate. If the value of ??? $DERP is less than the gas to claim it, inflation stops. The emission rate and frequency of claims will determine how long $DERP can be emitted for. Eventually emissions will cease.</p>
      <p>At launch there will be multiple mining events where both the claim amount and frequency may be increased.</p>
      <h2>General Treasury</h2>
      <p>Can be used as needed as the ecosystem evolves.</p>
      <h2>Bribes</h2>
      <p>Used to bribe voters on various dexes.</p>
      <h2>Team Locks</h2>
      <p>1-year minimum locks for team members.</p>
    </div>
    )
}
export function Section4() {
  return(
    <div className="section">
      <h1>Snackz</h1>
      <div className="imgs"><img src={snackCola} /> <img src={snackChips} /></div>
      <p>Snackz are managed by an ERC1155 contract. Snackz are mintable in $DERP and can be used to feed Derp NFTs. Each Snack costs a specific amount of $DERP. When fed to a Derp NFT, the Snack decreases the Derp's Hunger.</p>
      <p>The $DERP remains in the Snackz contract until the Snack is fed to a $DERP. Upon feeding:</p>
      <ol>
        <li>The Snack is burned</li>
        <li>??% of the $DERP is transferred to the Derp NFT owner.</li>
        <li>The remaining $DERP is burned.</li>
      </ol>
      <p>Once a Derp's Hunger reaches zero, it burps. Whoever fed it the Snack that made it burp gets a Berp NFT.</p>

    </div>
    )
}

export function Section5() {
  return(
    <div className="section">
      <h1>The Berps</h1>
      <p>A collection of Berps on Sonic Mainnet.</p>
      <p>Supply will be determined by currently undetermined $DERPonomics.</p>
      <div className="imgs"><img src={berp0} /> <img src={berp1} /><img src={berp2} /></div>
    </div>
    )
}

export function Section6() {
  return(
    <div className="section">
      <h1>The Derpmap</h1>
      <div className="img-full"><img src={derpmap} /></div>
      <h2>Phase 1 - The Derps Launch</h2>
      <p>Derp NFT Collection is launched. Funds are moved into the treasuries. Locks are purchased.</p>
      <p>You can expect this phase very soon</p>
      <h2>Phase 2 - $DERP Token Launch</h2>
      <p>$DERP token is launched. Funds are moved into the treasuries. Liquidity is added on Shadow. </p>
      <h2>Phase 3 - Snack Bar Launch</h2>
      <p>Snack Bar is launched. Users can begin hoarding snacks at a discounted purchase price.</p>
      <h2>Phase 4 - Berp Launch</h2>
      <p>Berps are launched. Users begin feeding Derps their Snackz. Snack Bar discount period ends.</p>
      <h2>Phase 5 - Continued Development</h2>
      <p>Since the supply of Berps will be relatively large, there will be a focus on building mini-games around teams of Berps. We also hope to enable other developers to build around the Berps.</p>
      </div>
    )
}